























import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'CompetitionRules',

  components: {
  },

  setup() {
    const model = ref(0);
    return {
      model,
    };
  },
});
