






















import NavBarWeb from '@/components/organisms/NavBarWeb.vue';
import { defineComponent } from '@vue/composition-api';
import NavBarMobile from '@/components/organisms/NavBarMobile.vue';

export default defineComponent({
  name: 'App',

  components: {
    NavBarWeb,
    NavBarMobile,
  },
});
