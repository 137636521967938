
















































































import { defineComponent } from '@vue/composition-api';
import Background from '@/components/atoms/Background.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Background,
  },
});
