





















































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DayItinerary',
  components: {
  },
  props: {
    day: {
      type: String,
      default: () => 0,
    },
    items: {
      type: Array,
      default: () => [{
        time: '0800-0900',
        duration: '1 hr',
        program: 'Wake up',
      }],
    },
  },
});
