







































































import { defineComponent } from '@vue/composition-api';
import authComposition from '@/composable/authHandler';

export default defineComponent({
  name: 'NavBarWeb',

  components: {
  },

  data: () => ({
    drawer: false,
  }),

  setup(props, { root }) {
    const {
      login, logout, isAuthenticated, role,
    } = authComposition(root);

    return {
      login,
      logout,
      role,
      isAuthenticated,
    };
  },
});
